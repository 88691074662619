import React, { useState, useEffect, useContext } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { MdOutlineError } from 'react-icons/md'
import { BsDownload } from 'react-icons/bs'
import { HiOutlineTrash } from 'react-icons/hi'
import { BiRightArrow } from 'react-icons/bi'
import { TbWorldDownload } from 'react-icons/tb'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { MdOutlineSummarize } from "react-icons/md";
import './table.css';
import ModalDocuments from '../modals/modalDocuments';
import PopOver from '../popOver/popOver'
import taxoApi from '../../api/taxoApi';
import { Context } from "../utils/store";
import ModalWarning from '../modals/modalWarning'
import { useNavigate } from 'react-router-dom';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function TableSearch(props) {

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ms-3">
            Mostrando de la {from} a la {to} de {size} evaluaciones
        </span>
    );

    const [productsState, setProductsState] = useState([]);
    const [state, dispatch] = useContext(Context);
    const [clickState, setClick] = useState(false)
    const [errorDelete, setErrorDelete] = useState(false);
    const [idEval, setIdEval] = useState()
    const [selectedEvaluation, setselectedEvaluation] = useState()
    const [viewDocs, setViewDocs] = useState(false)
    const navigate = useNavigate();

    const [errorDownload, setErrorDownload] = useState(false)
    const handleErrorDownload = (click) => setErrorDownload(click)

    const [windowWidth, setWindowWidth] = useState(getWindowDimensions().width);

    //Ancho de la ventana a partir del cual ocultamos columnas y las mostramos como filas
    const hiddingValues = { date: 800, opData: 0, sector: 1500, activity: 1200, status: 700, actions: 0,aligment: 600,bloqueData:1300}

    const [hideDate, setHideDate] = useState();
    const [hideOpData, setHideOpData] = useState();
    const [hideBloqueData, setHideBloqueData] = useState();
    const [hideSector, setHideSector] = useState();
    const [hideActivity, setHideActivity] = useState();
    const [hideStatus, setHideStatus] = useState();
    const [hideActions, setHideActions] = useState();
    const [hideAligment, setHideAligment] = useState();

    useEffect(() => {
        setProductsState(props.data)
    }, [props.data])

    useEffect(() => {
        function handleResize() {
            setWindowWidth(getWindowDimensions().width);
        }

        setHideDate(getWindowDimensions().width < hiddingValues.date);
        setHideOpData(getWindowDimensions().width < hiddingValues.opData);
        setHideBloqueData(getWindowDimensions().width < hiddingValues.bloqueData);
        setHideSector(getWindowDimensions().width < hiddingValues.sector);
        setHideActivity(getWindowDimensions().width < hiddingValues.activity);
        setHideStatus(getWindowDimensions().width < hiddingValues.status);
        setHideActions(getWindowDimensions().width < hiddingValues.actions);
        setHideAligment(getWindowDimensions().width < hiddingValues.aligment);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]);

    let size = 4
    if (props.size !== undefined) {
        size = props.size
    }

    // variables para la descarga del PDF
    const [existFile, setExistFile] = useState("0")
    const [checkPDF, setCheckPDF] = useState(0)
    
    const [currentEv,setCurrentEv] = useState(0)

    useEffect(() => {
        if(checkPDF !== 0 && existFile !== "0"){
            taxoApi.getTaxoDownloadFile(existFile,currentEv,0,navigate).then((response) => {
                if (response.status === '200') {
                    window.location.assign(response.data)
                } else {
                    props.handleErrorDownload(true)
                }
                setExistFile(0)
                setCheckPDF(0)
                dispatch({ type: "SET_HIDDEN", payload: true });
            })
        }
    }, [existFile])

    const checkFile = (evaluationId,fileType) => {
        taxoApi.getTaxoCheckFile(evaluationId, fileType, navigate).then((response) => {
            if (response.existFile !== "0") {
                switch(fileType){
                    case 0:
                        setExistFile(response.existFile);
                        break;
                    default:
                        break;
                }
            } else {
                setTimeout(checkFile(evaluationId,fileType), 10000);
            }
        });
    };

    const handleDownload = (Path,evaluationId,buscar) => {

        setCheckPDF(1)
        setCurrentEv(evaluationId)
        dispatch({ type: "SET_HIDDEN", payload: false });
        // Buscamos en bucle en caso de no tener la ruta del fichero a descargar
        if(Path !== null || Path !== "0" || Path !== undefined){
            try {
                checkFile(evaluationId,0);
            }
            catch (err) {
                console.error(err)
            }
        }else{ // cuando tengamos la ruta, la guardamos en ExistFile para ejecutar el useEffect de descarga
            setExistFile(Path)
        }
    }

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: size,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '4', value: 4
        }, {
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: '25', value: 25
        }, {
            text: '50', value: 50
        }
        ],
        /* , {
            text: 'All', value: productsState.length
        } */
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        alwaysShowAllBtns: true,
        // onPageChange: function (page, sizePerPage) {
        //     console.log('page', page);
        //     console.log('sizePerPage', sizePerPage);
        // },
        // onSizePerPageChange: function (page, sizePerPage) {
        //     console.log('page', page);
        //     console.log('sizePerPage', sizePerPage);
        // }
    });

    const handlerDeleteQuery = () => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoDeleteQuery(idEval).then((response) => {
            if (response.data.status === '200') {
                setProductsState(productsState.filter(item => item.evaluationId !== idEval))
            }
            else {
                setErrorDelete(true)
            }
            dispatch({ type: "SET_HIDDEN", payload: true });
        })

    }


    const handleClickState = (click) => setClick(click)
    const handlerErrorDelete = (click) => setErrorDelete(click)

    const handleDelete = (idEva) => {
        setIdEval(idEva)
        setClick(true)
    }


    const handleViewDocs = (click) => setViewDocs(click)

    const handleClickDocs = (evaluationId) => {
        setViewDocs(true)
        setselectedEvaluation(evaluationId)
    }

    const expandRow = {
        renderer: row => (
            <>
                {hideDate &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Fecha: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>{row.date}</span>
                        </div>
                    </div>
                }
                {hideOpData &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Datos Operación: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            {/* <span>{row.idOperation}{row.desc}{row.secIdOperation}</span> */}
                            <span>{row.idOperation}</span>
                        </div>
                    </div>}
                {hideBloqueData &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Nombre Bloque: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            {/* <span>{row.idOperation}{row.desc}{row.secIdOperation}</span> */}
                            <span>{row.idBloque}</span>
                        </div>
                    </div>}
                {hideSector &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Sector: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>{row.sector}</span>
                        </div>
                    </div>}

                {hideActivity &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Actividad: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>{row.activty}</span>
                        </div>
                    </div>}
                {hideStatus &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Estado: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>
                                
                                {row.status === 3 ? 'Error' : row.status === 1 ? 'Activa' : row.status === 2 ? 'Finalizada' : 'Inicial'}
                                {row.status === 3 ? <PopOver title={<MdOutlineError className='icon nErr' />} message={"Formato incorrecto del fichero"} /> :
                                    <span className={`badge ${row.status === 1 ? 'text-bg-pending' : row.status === 2 ? 'text-bg-success' : row.status === 3 ? 'text-bg-ko' : 'text-bg-initial'}`}>.</span>}
                            </span>
                        </div>
                    </div>}
                {hideActions &&
                    <div className='data-table-initial'>
                        <div className='sentence-initial-table col-4'>
                            <strong>Acciones: </strong>
                        </div>
                        <div className='w-auto data-initial-table data-cell-breakWord'>
                            <span>
                                
                                <Link className='play-button' to={`/evaluation/1/${row.sectorId}/${row.activityId}/${row.objectiveId}/${row.status}/${row.evaluationId}`} >
                                    <PopOver title={<BiRightArrow className='eye color-secondary' />} message={"Ver evaluación"} />
                                </Link>
                                {
                                    
                                    (row.alignmentStatus !== null && (row.blqName === null || row.blqName === "")) ?
                                    <PopOver title={<a className='play-button' onClick={() => (handleDownload(row.filePath,row.evaluationId,1))}>
                                    <BsDownload className='eye color-secondary ms-2' /></a>} message={"Descargar informe PDF"} />:
                                    <BsDownload className='eye color-gray ms-2' />
                                }
                                {row.numDocuments > 0 ?
                                    <PopOver title={<TbWorldDownload className='eye color-secondary ms-2' onClick={() => { handleClickDocs(row.evaluationId) }} />} message={"Documentación adjunta"} />
                                    :
                                    <TbWorldDownload className='eye color-gray ms-2' />
                                }
                                <PopOver title={<button className='play-button' onClick={() => handleDelete(row.evaluationId)}><HiOutlineTrash className='eye color-secondary' /></button>} message={"Eliminar"} />
                            </span>
                        </div>
                    </div>}
            </>
        ),
        showExpandColumn: windowWidth < Math.max(...Object.values(hiddingValues)),
        nonExpandable: windowWidth >= Math.max(...Object.values(hiddingValues)) ? (productsState.length ? [...Array(productsState[0].evaluationId + 1).keys()] : []) : [],
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return <b></b>;
            }
            return <b></b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<b className='cursor-pointer'>-</b>);
            }
            return (
                <b className='cursor-pointer'>+</b>
            );
        }
    }

    const columns = [
        {
            dataField: 'activty',
            text: 'Oculta',
            hidden: true,
            sort: false
        },
        {
            dataField: 'date',
            text: 'Fecha',
            style: { width: '6rem',minWidth: '6rem' },
            hidden: hideDate,
            sort: false
        },
        {
            dataField: 'desc',
            text: 'Nombre Operación',
            style: { maxWidth: '17rem',whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"},
            sort: false,
            hidden: hideOpData,
            formatter: (value, row) => (
                <span title={row.idOperation}>{row.idOperation}</span>
            )
        },
        {
            dataField: 'blqName',
            text: 'Nombre Bloque',
            sort: false,
            hidden: hideBloqueData,
            headerAlign: 'center',
            style: { width: '9rem' },
            align: 'center',
            formatter: (value, row) => (
                // <span>{row.idOperation} {value}{row.secIdOperation}</span>
                <span>{value.replace(".csv","") == "" ? "--":value.replace(".csv","")}</span>
            )
        },
        {
            dataField: 'sector',
            text: 'Sector',
            hidden: hideSector,
            style: { maxWidth: '13rem',whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"},
            sort: false,
            formatter: (value, row) => (
                <span title={value}>{value}</span>
            )
        },
        {
            dataField: 'activty',
            text: 'Actividad',
            hidden: hideActivity,
            style: { maxWidth: '19rem', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"},
            sort: false,
            formatter: (value, row) => (
                <span title={value}>{value}</span>
            )
        },
        {
            dataField: 'status',
            text: 'Estado',
            sort: false,
            headerAlign: 'center',
            hidden: hideStatus,
            style: { width: '6rem',minWidth: '6rem' },
            formatter: (value, row) => (
                <span className='float-end'>
                    {value === 3 ? 'Error' : value === 1 ? 'Activa' : value === 2 ? 'Finalizada ' : 'Inicial'}
                    {row.status === 3 ? <PopOver title={<MdOutlineError className='icon nErr' />} message={"Formato incorrecto del fichero"} /> :
                        <span className={`badge ${row.status === 1 ? 'text-bg-pending' : row.status === 2 ? 'text-bg-success' : row.status === 3 ? 'text-bg-ko' : 'text-bg-initial'}`}>.</span>}
                </span>
            )
        },
        {
            dataField: 'alignmentStatus',
            text: 'Resultado',
            hidden: hideAligment,
            sort: false,
            headerAlign: 'center',
            style: { width: '6rem',minWidth: '6rem' },
            align: 'center',
            formatter: (value, row) => (
                <span>
                    {value === 2 ? 'No alineada' : value === 1 ? 'Alineada' : '--'}
                </span>
            )
        },
        {
            dataField: 'actions',
            text: '',
            sort: false,
            align: 'center',
            hidden: hideActions,
            style: { width: '9rem',minWidth: '9rem' },
            formatter: (value, row) => (
                <span>
                    
                    <Link className='play-button' to={`/evaluation/1/${row.sectorId}/${row.activityId}/${row.objectiveId}/${row.status}/${row.evaluationId}`} >
                        <PopOver title={<BiRightArrow className='eye color-secondary' />} message={"Ver evaluación"} />
                    </Link>
                    {
                        (row.alignmentStatus !== null) ?
                        <Link className='play-button' to={`/summary/2/${row.sectorId}/${row.activityId}/${row.objectiveId}/${row.status}/${row.evaluationId}`} >
                            <PopOver 
                                title={
                                    <a className='play-button'>
                                        <MdOutlineSummarize className='eye color-secondary ms-2' />
                                    </a>} message={"Ver Resumen"}                                     
                            />
                        </Link>
                        :
                        <MdOutlineSummarize className='eye color-gray ms-2' />
                    }
                    {
                        (row.alignmentStatus !== null && (row.blqName === null || row.blqName === "")) ?
                        <PopOver title={<a className='play-button' onClick={() => (handleDownload(row.filePath,row.evaluationId,1))}>
                        <BsDownload className='eye color-secondary ms-2' /></a>} message={"Descargar informe PDF"} />:
                        <BsDownload className='eye color-gray ms-2' />
                    }
                    {row.numDocuments > 0 ?
                        <PopOver title={<TbWorldDownload className='eye color-secondary ms-2' onClick={() => { handleClickDocs(row.evaluationId) }} />} message={"Documentación adjunta"} />
                        :
                        <TbWorldDownload className='eye color-gray ms-2' />
                    }
                    <PopOver title={<button className='play-button' onClick={() => handleDelete(row.evaluationId)}><HiOutlineTrash className='eye color-secondary' /></button>} message={"Eliminar"} />
                </span>
            )
        }

    ];

    const defaultSorted = [{
        dataField: 'date',
        order: 'desc'
    }];



    return (
        <div>
            {viewDocs && <ModalDocuments show={viewDocs} handle={handleViewDocs} evaluationId={selectedEvaluation} showUpload={false}></ModalDocuments>}
            {
                (productsState && productsState.length === 0) ? <div className='no-result'>No hay resultados para los criterios de búsqueda seleccionados</div> :
                    <BootstrapTable responsive
                        classes='table-basic table-hover table-borderless'
                        bootstrap4
                        keyField='evaluationId'
                        data={productsState}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        pagination={pagination}
                        expandRow={expandRow}
                    />

            }
            <ModalWarning header={<span className='section-title-modal'>Eliminar evaluación</span>} message={"¿Está seguro de que quiere eliminar la evalución?"} show={clickState} handle={handleClickState} handleContinue={handlerDeleteQuery} > </ModalWarning>
            <ModalWarning header={<span className='section-title-modal'>Error</span>} message={"No se ha podido borrar la evaluación"} show={errorDelete} handle={handlerErrorDelete} onlyButtonContinue> </ModalWarning>
        </div>
    );
}


export default TableSearch;

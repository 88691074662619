import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import './buttonNewAction.css'
import pdfA from '../../api/informe_PDF_Adquisicion.pdf'
import pdfT from '../../api/informe_PDF_Transporte.pdf'
import pdfE from '../../api/informe_PDF_Energia.pdf'
import csvA from '../../api/AdquisicionCsv.csv'
import csvT from '../../api/TransporteCsv.csv'
import csvE from '../../api/EnergiaCsv.csv'
import ModalWarning from '../modals/modalWarning'
import UploadFile from '../uploadFile/uploadFile'
import { Button } from 'react-bootstrap';
import { BiPlus } from "react-icons/bi";
import taxoApi from '../../api/taxoApi';
import { Context } from "../../commons/utils/store";


export default function ButtonNewAction(props) {
    const [state, dispatch] = useContext(Context);
    const onClicked = (redir, page) => {
        props.handleClick(redir, page)
    }

    const navigate = useNavigate();

    const [show, setShow] = useState(false)
    const handleShow = (v) => {
        setShow(v)
    }

    // variables para la descarga del PDF
    const [existFile, setExistFile] = useState("0")
    const [checkPDF, setCheckPDF] = useState(0)

    // variables para la descarga de datos de entrada CSV
    const [existFileCSV, setExistFileCSV] = useState("0")
    const [checkCSV, setCheckCSV] = useState(0)

    // variables para la descarga de resultados CSV
    const [existFileCSVRes, setExistFileCSVRes] = useState("0")
    const [checkCSVRes, setCheckCSVRes] = useState(0)

    useEffect(() => {
        if(checkPDF !== 0 && existFile !== "0"){
            taxoApi.getTaxoDownloadFile(existFile,props.evaluationId,0,navigate).then((response) => {
                if (response.status === '200') {
                    window.location.assign(response.data)
                } else {
                    props.handleErrorDownload(true)
                }
                setExistFile(0)
                setCheckPDF(0)
                dispatch({ type: "SET_HIDDEN", payload: true });
            })
        }
        if(checkCSV !== 0 && existFileCSV !== "0"){
            taxoApi.getTaxoDownloadFile(existFileCSV,props.evaluationId,0,navigate).then((response) => {
                if (response.status === '200') {
                    window.location.assign(response.data)
                } else {
                    props.handleErrorDownload(true)
                }
                setExistFileCSV(0)
                setCheckCSV(0)
                dispatch({ type: "SET_HIDDEN", payload: true });
            })
        }
        if(checkCSVRes !== 0 && existFileCSVRes !== "0"){
            taxoApi.getTaxoDownloadFile(existFileCSVRes,props.evaluationId,0,navigate).then((response) => {
                if (response.status === '200') {
                    window.location.assign(response.data)
                } else {
                    props.handleErrorDownload(true)
                }
                setExistFileCSVRes(0)
                setCheckCSVRes(0)
                dispatch({ type: "SET_HIDDEN", payload: true });
            })
        }
    }, [existFile,existFileCSV,existFileCSVRes])

    
    const checkFile = (evaluationId,fileType) => {
        taxoApi.getTaxoCheckFile(evaluationId, fileType, navigate).then((response) => {
            if (response.existFile !== "0") {
                switch(fileType){
                    case 0:
                        setExistFile(response.existFile);
                        break;
                    case 1:
                        setExistFileCSV(response.existFile);
                        break;
                    case 2:
                        setExistFileCSVRes(response.existFile);
                        break;
                    default:
                        break;
                }
            } else {
                setTimeout(checkFile(evaluationId,fileType) , 10000);
            }
        });
    };

    const handleDownload = (Path,evaluationId,buscar) => {
        setCheckPDF(1)
        dispatch({ type: "SET_HIDDEN", payload: false });
        try {
            checkFile(evaluationId,0);
        }
        catch (err) {
            console.error(err)
        }
    }

    const handleDownloadCSV = (Path,evaluationId,buscar) => {
        setCheckCSV(1)
        dispatch({ type: "SET_HIDDEN", payload: false });
        try {
            checkFile(evaluationId,1);
        }
        catch (err) {
            console.error(err)
        }
    }

    const handleDownloadCSVRes = (Path,evaluationId,buscar) => {
        setCheckCSVRes(1)
        dispatch({ type: "SET_HIDDEN", payload: false });
        try {
            checkFile(evaluationId,2);
        }
        catch (err) {
            console.error(err)
        }
    }

    const [fich, setFich] = useState('')
    const handle = (v) => {
        setFich(v)
    }

    const onClickAct = (urlNavigate) => {
        if (props.nChanges === undefined || (props.nChanges !== undefined && props.nChanges.length === 0)) {
            navigate(urlNavigate)
        }
        else {
            let hasChanges = false
            if (props.nChanges.length > 0) {
                props.nChanges.forEach(item => {
                    if (item.num > 0) {
                        hasChanges = true;
                        return;
                    }

                })
            }

            if (hasChanges)
                onClicked(true, urlNavigate)
            else
                navigate(urlNavigate)
        }
    }

    const batchEvaluationButton = () => 
    {
        let button =
            <>
            <Button className='justify-content-center button-primary long-button' onClick={() => handleShow(true)}>Nueva Evaluación en bloque</Button>
            <DropdownButton className='d-sm-none justify-content-center button-primary long-button' id="dropdown-basic-button" title={<BiPlus className='d-sm-none plus-botton h0'> </BiPlus>} align='right' drop='down-centered'>
                <Dropdown.Item className='dropdown-item-new-action fs-8' onClick={() => handleShow(true)}>
                    Importar fichero de datos
                </Dropdown.Item>
            </DropdownButton>
            </>
        return button
    }

    const singleEvaluationButton = () => {
        let button = 
        <DropdownButton className='button-primary long-button' id="dropdown-basic-button" title={<div className='d-inline'><span className='d-none d-sm-inline'>Nueva acción</span> <BiPlus className='d-sm-none plus-botton h0'> </BiPlus></div>} align='right'>
            {(props.state === 2 || props.state === '2') ?
                <>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' onClick={() => props.onHandleStateEvaluation(1)}>
                        Reabrir Evaluación
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item-new-action fs-8'
                        onClick={() => (handleDownloadCSV("0",props.evaluationId,1))}
                        >
                        Descarga de datos (csv)
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item-new-action fs-8'
                        onClick={() => (handleDownloadCSVRes("0",props.evaluationId,1))}
                        >
                        Descarga de Resultados (csv)
                    </Dropdown.Item>                                    
                    <Dropdown.Item className='dropdown-item-new-action fs-8' 
                        onClick={() => (handleDownload("0",props.evaluationId,1))}
                        >
                        Descarga de informe PDF
                    </Dropdown.Item>
                </>
                :
                <>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' disabled={true}>
                        Reabrir Evaluación
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' disabled={true}>
                        Descarga de datos (csv)
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' disabled={true}>
                        Descarga de Resultados (csv)
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' disabled={true}>
                        Descarga de informe PDF
                    </Dropdown.Item>
                </>
            }
        </DropdownButton>
        return button
    }

    const summaryEvaluationButton = () => {
        let button = 
        <DropdownButton className='button-primary long-button' id="dropdown-basic-button" title={<div className='d-inline'><span className='d-none d-sm-inline'>Nueva acción</span> <BiPlus className='d-sm-none plus-botton h0'> </BiPlus></div>} align='right'>
            {(props.state === 2 || props.state === '2') ?
                <>
                    <Dropdown.Item className='dropdown-item-new-action fs-8'
                        onClick={() => (handleDownloadCSV("0",props.evaluationId,1))}
                        >
                        Descarga de datos (csv)
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item-new-action fs-8'
                        onClick={() => (handleDownloadCSVRes("0",props.evaluationId,1))}
                        >
                        Descarga de Resultados (csv)
                    </Dropdown.Item>                                    
                    <Dropdown.Item className='dropdown-item-new-action fs-8' 
                        onClick={() => (handleDownload("0",props.evaluationId,1))}
                        >
                        Descarga de informe PDF
                    </Dropdown.Item>
                </>
                :
                <>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' disabled={true}>
                        Descarga de datos (csv)
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' disabled={true}>
                        Descarga de Resultados (csv)
                    </Dropdown.Item>
                    <Dropdown.Item className='dropdown-item-new-action fs-8' disabled={true}>
                        Descarga de informe PDF
                    </Dropdown.Item>
                </>
            }
        </DropdownButton>
        return button
    }

    return (
        <div className={`me-3 d-inline`}>
            {props.isBatchEvaluation ?
                batchEvaluationButton()
            : 
            props.isEvaluationPage ?
                singleEvaluationButton() 
            :
            props.isSummaryPage ?
                summaryEvaluationButton() : <></> 
                
            }
            <ModalWarning header={<span className='section-title-modal'>Importar fichero de datos</span>} message={<UploadFile handle={handle} handleShow={handleShow} handleRefreshBlocks={props.handleRefreshBlocks}></UploadFile>} show={show} handle={handleShow} noButtons big> </ModalWarning>

        </div>)
}

import React, { useState, useContext, useEffect } from 'react';
import { Form, Tab, Tabs, Button, Alert, Accordion } from 'react-bootstrap';
import ComponentQuestions from '../componentQuestions/componentQuestions';
import PopOver from '../../../commons/popOver/popOver'
import ModalWarning from '../../../commons/modals/modalWarning'
import ModalMessage from '../../../commons/modals/modalMessage'
import ModalDocuments from '../../../commons/modals/modalDocuments';
import { GiSandsOfTime } from "react-icons/gi";
import { BiInfoCircle,BiCommentDetail } from "react-icons/bi";
import { BsCheckCircleFill, BsFileEarmarkCheckFill } from "react-icons/bs"
import { MdCancel } from "react-icons/md"
import { AiOutlineFileDone,AiOutlineClose } from "react-icons/ai"
import { RiFileExcel2Fill } from "react-icons/ri"
import { TbWorldDownload } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom';
import taxoApi from '../../../api/taxoApi';
import ObjectiveImage from '../../../commons/objectiveImage/objectiveImage'
import { Context } from "../../../commons/utils/store";
import ResponseSALV from "../../../api/responseSALV"
import pdf from '../../../api/informe_PDF_Adquisicion.pdf'



export default function ComponentSteps({ data, data2, dateOp, nameOp, descOp, onHandleChanges, onHandleStateEvaluation, onHandleSave,handleSetEvaluationId,evaluationId,handleErrorDownload,esBloque,calculateAligment} = {}) {

    const [state, dispatch] = useContext(Context);
    const [info, setInfo] = useState(data)
    const [idActivity,SetIdActivity] = useState(data.idActivity)
    const [objetivo,setObjetivo] = useState(data.idObjective)
    const [cont, setCont] = useState(0)

    const [showSave, setShowSave] = useState(false);

    if (data2 === undefined) {
        data2 = {}
    }

    const [responseEval, setResponseEval] = useState([])
    const [errorMsg, setErrorMsg] = useState('')

    const [clickFin, setClickFin] = useState(false)
    const handleClickState = (click) => setClickFin(click)
    //const [end, setEnd] = useState(false)

    const [viewComments, setViewComments] = useState(false)
    const [viewCommentsFirst, setViewCommentsFirst] = useState(false)
    const handleViewComments = (click) => setViewComments(click)

    const [viewDocs, setViewDocs] = useState(false)
    const [viewDocsFirst, setViewDocsFirst] = useState(false)
    const handleViewDocs = (click) => setViewDocs(click)

    const navigate = useNavigate();
    const [msgSave, setmsgSave] = useState('')

    const [respComment, setRespComment] = useState()

    const tab = '\u00A0\u00A0';

    let NA = "N/A";
    let op = "";
    let opAux = "";

    //TODO: Revisar esto, para quitar el hardcode cuando se cargue de back
    if (!dateOp || !nameOp || !descOp) {
        opAux = 'ABA-00021' + tab + tab + 'Operación-1234567';
        if (info !== undefined && info !== null && info.evaluationId !== null) {
            op = info.date + tab + info.idOperation + ' Sec. - ' + info.evaluationId;
        }
        else {
            op = info.date + tab + opAux + ' Sec. - ' + NA;
        }
    }
    else {
        op = "" + dateOp + tab + nameOp + tab + tab + descOp;
    }

    const [click, setShow] = useState(false)
    const handleShow = () => setShow(!click);


    function handleObjectChange(evt, org, orgChild, sumar, pIdEvaluationType) {
        onHandleChanges(sumar, pIdEvaluationType)

        let newInfo
        if (orgChild) {
            newInfo = { ...data.evaluationTypes[org].idEvaluationAux[orgChild], questionList: evt }
            info.evaluationTypes[org].idEvaluationAux[orgChild] = newInfo
            setInfo(info)
        }
        else {
            newInfo = { ...data.evaluationTypes[org], questionList: evt }
            info.evaluationTypes[org] = newInfo
            setInfo(info)
        }

    }

    async function handleSubmit(event) {
        event.preventDefault()
        // let information = { ...data, evaluationTypes: info.evaluationTypes }
    }

    const [scroll, setScroll] = useState(false)
    useEffect(() => {
        if (scroll) {
            var tabContainer = document.querySelector('.tab-pane.active.show')
            var top = tabContainer?.querySelector('.result-block')?.offsetTop

            var prueba = document.getElementsByTagName('html')[0]
            prueba.scrollTop = top
            setScroll(false)
        }
    }, [cont])

    useEffect(() => {
        setInfo(null)
    }, [data])


    useEffect(() => {
        if (data.idOperation !== undefined && data.idOperation === null) {
            data.idOperation = opAux     
        }

        if(data.evaluationId === null && data.idObjective == "MITG" && (data.idActivity == 7007 || data.idActivity == 6005)){
            handleEval(null, "SALV")
        }

        setInfo(data)
        //TODO quitar truñaco hardcodeado
        
        // setResponseEval(data2)
    }, [info])


    /*   useEffect(() => {
            setResponseEval(null)
        }, [data2])*/

    useEffect(() => {
        setResponseEval(data2)
    }, [])

    const [existFile, setExistFile] = useState("0")
    const [checkPDF, setCheckPDF] = useState(0)

    const [showInfoCS,setShowInfoCS] = useState(true)
    const [showInfoDNSH,setShowInfoDNSH] = useState(true)
    const [showInfoSALV,setShowInfoSALV] = useState(true)

    const handleSetShowInfoCS = () => {
        setShowInfoCS(false)
    }

    const handleSetShowInfoDNSH = () => {
        setShowInfoDNSH(false)
    }

    const handleSetShowInfoSALV = () => {
        setShowInfoSALV(false)
    }

    useEffect(() => {
        if(checkPDF !== 0 && existFile !== "0"){
            taxoApi.getTaxoDownloadFile(existFile,evaluationId,0,navigate).then((response) => {
                if (response.status === '200') {
                    window.location.assign(response.data)
                } else {
                    handleErrorDownload(true)
                }
                setExistFile(0)
                setCheckPDF(0)
                dispatch({ type: "SET_HIDDEN", payload: true });
            })
        }
    }, [existFile])

    const checkFile = (evaluationId,fileType) => {
        taxoApi.getTaxoCheckFile(evaluationId, fileType, navigate).then((response) => {
            if (response.existFile !== "0") {
                switch(fileType){
                    case 0:
                        setExistFile(response.existFile);
                        break;
                    default:
                        break;
                }
            } else {
                setTimeout(checkFile(evaluationId,fileType), 10000);
            }
        });
    };

    const handleDownload = (Path,evaluationId,buscar) => {
        setCheckPDF(1)
        dispatch({ type: "SET_HIDDEN", payload: false });
        try {
            checkFile(evaluationId,0);
        }
        catch (err) {
            console.error(err)
        }
    }

    const handleEndEvaluation = () => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoEndEvaluation(info)
            .then((response) => {
                if (response.status === 'OK') {
                    info.state = 2
                    onHandleStateEvaluation(info.state)
                    setInfo(info)
                    handleSetEvaluationId(response.evaluationId)
                    calculateAligment(responseEval)
                }

                dispatch({ type: "SET_HIDDEN", payload: true });
            })

    }

    const handleShowModal = (v) => {
        setShowSave(v)
    }


    const handleSave = (e, id) => {
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoSave(id, info, navigate)
            .then((response) => {
                if ('error' in response) {
                    setmsgSave(Object.values(response.error.statusText))
                }
                else if ('data' in response) {
                    setmsgSave(Object.values(response.data.evaluationTypes)[0].saveDetail)
                    info.evaluationId = response.data.evaluationId
                    onHandleStateEvaluation(1)
                    setInfo(info)
                    onHandleSave(id, false)
                }
                else {
                    setmsgSave("Ha ocurrido un error al guardar")
                }
                dispatch({ type: "SET_HIDDEN", payload: true });
                //setResponseEval(responseEval)    
                setShowSave(true)

            })


    }

    // const handlerComment = () => {
    //     dispatch({ type: "SET_HIDDEN", payload: false });
    //     taxoApi.getTaxoGetQueryComments(info.evaluationId,navigate)     
    //     .then((response)=> {            
    //         // into.message = response
    //         // setInfo(info)
    //         setRespComment(response);
    //         setClickMessage(true);
    //         dispatch({ type: "SET_HIDDEN", payload: true });
    //     })
    // }

    const handleEval = (e, id) => {
        setScroll(true)
        dispatch({ type: "SET_HIDDEN", payload: false });
        taxoApi.getTaxoEvaluation(id, info, navigate)
            .then((response) => {
                let responseAux = {}
                if ('data' in response) {
                    response.data.forEach(item => {
                        responseAux = responseEval
                        if (responseAux !== undefined && responseAux['evaluationTypes'] !== undefined) {
                            responseAux['evaluationTypes'][id] = item.evaluationTypes[id]
                        }
                        else {
                            responseAux = item
                        }

                        // if (fin) {
                        //     let numberSteps = Object.keys(info.evaluationTypes).length
                        //     if (Object.keys(responseEval).length === numberSteps) {
                        //         info.state = 2
                        //     }
                        // }
                        //info.state = 1
                        onHandleStateEvaluation(1)
                        info.evaluationId === null ? onHandleSave(id, false) : onHandleSave(id, true)
                        info.evaluationId = item.evaluationId
                        setInfo(info)
                        setResponseEval(responseAux)

                        setCont(cont + 1)
                        dispatch({ type: "SET_HIDDEN", payload: true })
                    })
                }
                else {
                    // if (id === "SALV") {
                    //     ResponseSALV.data.forEach(item => {
                    //         responseEval[id] = item[id]
                    //         responseEval[id].errorMsg = null
                    //         if (info.evaluationId === NA) {
                    //             info.evaluationId = 1
                    //         }
                    //     })



                    //     // if (fin) {
                    //     //     if (Object.keys(responseEval).length === Object.keys(info.evaluationTypes).length) {
                    //     //         info.state = 2
                    //     //     }
                    //     // }

                    //     //infor.state =1
                    //     onHandleStateEvaluation(1)
                    //     info.evaluationId===null ? onHandleSave(id, false) : onHandleSave(id, true)
                    //     setInfo(info)
                    //     setResponseEval(responseEval)

                    //     setCont(cont + 1)
                    //     dispatch({ type: "SET_HIDDEN", payload: true })
                    // }
                    // else {
                    if (responseEval !== undefined && responseEval['evaluationTypes'] !== undefined) {
                        responseEval['evaluationTypes'][id] = {}
                        responseEval['evaluationTypes'][id].errorMsg = 'Error al realizar la evaluación'
                    }
                    else {
                        responseEval['evaluationTypes'] = {}
                        responseEval['evaluationTypes'][id] = {}
                        responseEval['evaluationTypes'][id].errorMsg = 'Error al realizar la evaluación'
                    }

                    dispatch({ type: "SET_HIDDEN", payload: true });
                    // }
                }
            })

    }

    var numDoc = 0;
    var printTable = true;
    var detailDNSH = [];
    var resDNSH = "OK"
    var ind = 0;
    return (
        <div>
            <div id='evaluation' className='section-block'>
                <div className="section-content">
                    <div className='div-end'>
                        <div className='actions'>
                            {info.evaluationId ?
                                <PopOver title={<div className='circle-icon me-2' onClick={() => { info.evaluationId && setViewComments(true); setViewCommentsFirst(true) }}><BiCommentDetail className='color-icon eye' /></div>} message={"Comentarios"} />
                                :
                                <div className='circle-icon me-2' ><BiCommentDetail className='color-gray eye' /></div>
                            }
                            {info.evaluationId && viewCommentsFirst && <ModalMessage evaluationId={info.evaluationId} header={'Comentarios'} show={viewComments} handle={handleViewComments}></ModalMessage>}
                            {/* <PopOver title={<div className='circle-icon me-2'><a href={pdf} target="_blank" rel="noopener noreferrer" download={"Documentación_Relacionada" + ++numDoc}><TbWorldDownload style={{marginBottom: '0.15rem'}} className='color-icon eye' /></a></div>} message={"Exportar/Importar documentación"} /> */}

                            {info.evaluationId ?
                                <PopOver title={<div className='circle-icon me-2' onClick={() => { setViewDocs(true); setViewDocsFirst(true) }}><TbWorldDownload style={{ marginBottom: '0.15rem' }} className='color-icon eye' /></div>} message={"Documentación adjunta"} />
                                :
                                <div className='circle-icon me-2' ><TbWorldDownload style={{ marginBottom: '0.15rem' }} className='color-gray eye' /></div>
                                // <PopOver title={<div className='circle-icon me-2' ><TbWorldDownload style={{marginBottom: '0.15rem'}} className='color-gray eye' /></div>} message={"Se debe guardar la evaluación para adjuntar documentación"} />

                            }
                            {viewDocsFirst && <ModalDocuments show={viewDocs} handle={handleViewDocs} evaluationId={info.evaluationId} showUpload={true}></ModalDocuments>}
                            {/* {(responseEval && responseEval['evaluationTypes'] && info && info.evaluationTypes && Object.keys(responseEval['evaluationTypes']).length === Object.keys(info.evaluationTypes).length && info.state !== 2) ?
                                <>
                                    <PopOver title={<div className='circle-icon me-2' onClick={() => { setClickFin(true) }}><BsFileEarmarkCheckFill className='color-icon eye' /></div>} message={"Finalizar Evaluación"} />
                                    <ModalWarning header={<span className='section-title-modal'>Finalizar evaluación</span>} message={"Se finalizará la evaluación. Al hacer click en continuar no se podrán cambiar las respuestas"} show={clickFin} handleContinue={handleEndEvaluation} handle={handleClickState}></ModalWarning>
                                </> :
                                (info && info.state !== 2) ?
                                    // <PopOver title={<div className='circle-icon me-2' ><BsFileEarmarkCheckFill className='color-gray eye' /></div>} message={'Se deben evaluar todos los pasos antes de finalizar'} />
                                    <div className='circle-icon me-2' ><BsFileEarmarkCheckFill className='color-gray eye' /></div>
                                    :
                                    <PopOver title={<div className='circle-icon me-2' ><BsFileEarmarkCheckFill className='color-icon eye' /></div>} message={'Evaluación ya finalizada'} />

                            } */}
                        </div>

                        {/* {responseEval !== undefined && responseEval.CS !== undefined && responseEval.SALV !== undefined && info.state === 2 &&
                    responseEval.CS.resultCode === 'OK' && resDNSH === 'OK' && responseEval.SALV.resultCode === 'OK' && <h6 className='float-end me-5'><span className='border-result'><BsCheckCircleFill className='color-succes pb-0 me-2' />Alineada</span> Scoring: 100%</h6>}     */}
                    </div>
                    <Tabs
                        className=''
                        defaultActiveKey="CS"
                        transition={true}
                        id="noanim-tab-example"
                    >
                        {info !== undefined && info !== null &&
                            Object.entries(info.evaluationTypes).map(([key, obj]) => (

                                //<Tab disabled={Object.keys(responseEval).length === 0 || responseEval['CS'] === undefined || (key !== 'CS' && responseEval['CS'].resultCode === 'KO')} eventKey={key} title={`Paso ${ind = ind + 1}: ${obj.nameEvaluationType}`}>
                                //<Tab eventKey={key} title={`Evaluación ${obj.nameEvaluationType == "No daño significativo"?"DNSH":obj.nameEvaluationType}`}>
                                <Tab eventKey={key} title={`Paso ${ind = ind + 1}: ${obj.nameEvaluationType}`}>
                                    <Form onSubmit={handleSubmit}>
                                        <div>
                                            <div className='row'>
                                                <div className='col-sm-3 col-md-2 col-primary py-1'>
                                                    Operación
                                                </div>
                                                <div className='col-sm-9 col-md-10 col-secondary py-1'>
                                                    {/* {!utilsMethods.isNullOrEmpty(info.evaluationId) ? op + na : op + na} */}
                                                    {/* {info.date} {info.idOperation} Sec. - {info.secIdOperation} */}
                                                    {info.date} {info.idOperation}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-3 col-md-2 col-primary py-1'>
                                                    Objetivo
                                                </div>
                                                <div className='col-sm-9 col-md-10 col-secondary py-1'><span>
                                                    {/*TODO incluirn iconos en base de datos*/}
                                                    <ObjectiveImage objectiveId={info.idObjective} block={'block-size'} /> {info.nameObjective}
                                                </span>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-3 col-md-2 col-primary py-1'>
                                                    Sector
                                                </div>
                                                <div className='col-sm-9 col-md-10 col-secondary py-1'>
                                                    {info.idSector}-{info.nameSector}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-3 col-md-2 col-primary-end py-1'>
                                                    Actividad
                                                </div>
                                                <div className='col-sm-9 col-md-10 col-secondary-end py-1'>
                                                    <div>
                                                        {info.activityCode}. {info.nameActivity}
                                                        <div className='float-end color-secondary cursor-pointer'>
                                                            <PopOver title={'Descripción'} message={info.activityDesc} position={'auto'} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                {(!obj.idEvaluationAux ?
                                                    <div className={info.state == 2 ? 'is-disable mt-3 mb-4' : 'mt-3 mb-4'}>
                                                        <ComponentQuestions onObjectChange={handleObjectChange} preguntas={obj.questionList} org={key} idAct={idActivity} tab={obj.idEvaluationType} obj={objetivo}/>
                                                    </div>
                                                    : // Pintando el Paso 2
                                                    <Accordion className='myAcordion mb-4' alwaysOpen>
                                                        {Object.entries(obj.idEvaluationAux).map(([keyChild, aux]) => (
                                                            <>
                                                                {aux.isAssessable === 1 ?
                                                                    <>
                                                                        <Accordion.Item eventKey={aux.idObjectiveAux}>
                                                                            <Accordion.Header>
                                                                                {/* <div className='row box col-secondary-bottom eval-section'></div> */}
                                                                                <div className='myAcordion-label'>
                                                                                    <span>
                                                                                        <ObjectiveImage objectiveId={aux.idObjectiveAux} />
                                                                                        {aux.nameObjectiveAux}
                                                                                    </span>
                                                                                </div>
                                                                                <div className='col-3 myAcordion-status'>
                                                                                    {responseEval && responseEval['evaluationTypes'] && responseEval['evaluationTypes'][key] && responseEval['evaluationTypes'][key].evaluationAux[keyChild].resultCode === 'KO' ?
                                                                                        <><MdCancel className='col-3 color-fail pb-0 me-2 icon-acordion plus-size' />No Cumple</>
                                                                                        :
                                                                                        (responseEval && responseEval['evaluationTypes'] && responseEval['evaluationTypes'][key] && responseEval['evaluationTypes'][key].evaluationAux[keyChild].resultCode === 'N/A' ?
                                                                                        <><MdCancel className='col-3 color-pending pb-0 me-2 icon-acordion plus-size' />No evaluado</>
                                                                                        :
                                                                                        responseEval && responseEval['evaluationTypes'] && responseEval['evaluationTypes'][key] && responseEval['evaluationTypes'][key].evaluationAux[keyChild].resultCode === "OK" ?
                                                                                            <><BsCheckCircleFill className='col-3 color-succes pb-0 me-2 icon-acordion ' />Cumple </> :
                                                                                            <><GiSandsOfTime className='col-3 color-pending pb-0 me-2 icon-acordion plus-size' />Pendiente </>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </Accordion.Header>
                                                                            <Accordion.Body>
                                                                                <div className={info.state == 2 ? 'is-disable mt-3 ' : 'mt-3'}>
                                                                                    <ComponentQuestions onObjectChange={handleObjectChange} preguntas={aux.questionList} orgChild={keyChild} org={key} idAct={idActivity} />
                                                                                </div>
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className='row box col-secondary-bottom eval-section'>
                                                                            <div className='myAcordion-eval'>
                                                                                <span>
                                                                                    <ObjectiveImage objectiveId={aux.idObjectiveAux} />
                                                                                    {aux.nameObjectiveAux}
                                                                                </span>
                                                                            </div>
                                                                            <div className='margin-md'>
                                                                                <><BsCheckCircleFill className='color-succes  pb-0 me-2' />Cumple</>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {printTable = false}
                                                            </>
                                                        ))}
                                                    </Accordion>
                                                )}
                                                {
                                                    (info && info.state !== 2 && obj.idEvaluationType == 'CS' && showInfoCS) &&
                                                    <div className='row py-2 mt-5 mb-4'>
                                                        <div className='info-note'>
                                                            <div className='d-flex'>
                                                                {<BiInfoCircle className='h5 info-icon' />} 
                                                                <p className='mb-0'>Recuerde que para completar la evaluación debe evaluar cada uno de los pasos. Si desea generar el informe final resultado de la evaluación, recuerde que debe finalizar dicha evaluación. </p>                                                        
                                                            </div>
                                                            {<AiOutlineClose onClick={() => { handleSetShowInfoCS() }} className='h4 info-icon' />} 
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (info && info.state !== 2 && obj.idEvaluationType == 'DNSH' && showInfoDNSH) &&
                                                    <div className='row py-2 mt-5 mb-4'>
                                                        <div className='info-note'>
                                                            <div className='d-flex'>
                                                                {<BiInfoCircle className='h5 info-icon' />} 
                                                                <div>
                                                                    <p className="mb-0">Para evaluar los criterios DNSH debe evaluar cada uno de los objetivos que se indican como “Pendiente”. Por defecto, algunos de los objetivos N/A evaluación o cumple por defecto.</p>
                                                                    <p className='mb-0'>Recuerde que para completar la evaluación debe evaluar cada uno de los pasos. Si desea generar el informe final resultado de la evaluación, recuerde que debe finalizar dicha evaluación. </p>                                                        
                                                                </div>
                                                            </div>
                                                            {<AiOutlineClose onClick={() => { handleSetShowInfoDNSH() }} className='h4 info-icon' />} 
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (info && info.state !== 2 && obj.idEvaluationType == 'SALV' && showInfoSALV) &&
                                                    <div className='row py-2 mt-5 mb-4'>
                                                        <div className='info-note'>
                                                            <div className='d-flex'>
                                                                {<BiInfoCircle className='h5 info-icon' />} 
                                                                <div>
                                                                 <p className="mb-0">De acuerdo con el artículo 18 del Reglamento (UE) 2020/852 sobre taxonomía, las garantías mínimas a que se refiere el artículo 3, letra c) la actividad económica debe garantizar la alineación con las Líneas Directrices de la OCDE y los Principios Rectores de las Naciones Unidas y los Derechos Humanos.</p>
                                                                    <p className="mb-0">Para determinadas actividades económicas estas mínimas salvaguardas se cumplen por defecto.</p>
                                                                    <p className='mb-0'>Recuerde que para completar la evaluación debe evaluar cada uno de los pasos.Si desea generar el informe final resultado de la evaluación, recuerde que debe finalizar dicha evaluación. </p>                                                        
                                                                </div>             
                                                            </div>
                                                            {<AiOutlineClose onClick={() => { handleSetShowInfoSALV() }} className='h4 info-icon' />} 
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {responseEval && responseEval['evaluationTypes'] !== undefined && responseEval['evaluationTypes'][key] !== undefined &&
                                            <div className='special-block result-block' id={key} >
                                                <div className='bg-white special-content'>
                                                    <h6 className='result'> Resultado {obj.nameEvaluationType}</h6>
                                                    {responseEval['evaluationTypes'][key].errorMsg ? <>
                                                        <Alert key='danger' variant='danger'>
                                                            {responseEval['evaluationTypes'][key].errorMsg}
                                                        </Alert>
                                                    </> :
                                                        !responseEval['evaluationTypes'][key].evaluationAux && !responseEval['evaluationTypes'][key].errorMsg ? <>
                                                            {/* {setCountResponse(countResponse+1)} */}
                                                            <div className='content'>
                                                                <div className='row'>
                                                                    <div className='col-sm-3 col-lg-2 col-primary py-2 d-flex align-items-center'>
                                                                        Resultado
                                                                    </div>
                                                                    <div className='col-sm-9 col-lg-10 col-secondary py-2 gray-border'>

                                                                        {responseEval['evaluationTypes'][key].resultCode === 'OK' ?
                                                                            <div>
                                                                                <div>
                                                                                    <BsCheckCircleFill className='color-succes pb-0 me-2' />
                                                                                    Cumple
                                                                                </div>
                                                                            </div> : 
                                                                            (responseEval['evaluationTypes'][key].resultCode === 'KO' ?
                                                                                <div>
                                                                                    <div>
                                                                                        <MdCancel className='color-fail pb-0 me-2' />
                                                                                            No Cumple
                                                                                    </div>
                                                                                </div> : 
                                                                                <div>
                                                                                    <div>
                                                                                        <MdCancel className='color-pending pb-0 me-2' />
                                                                                        No evaluado
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-sm-3 col-lg-2 col-primary-end py-2'>
                                                                        Detalle de la evaluación
                                                                    </div>
                                                                    <div className='col-sm-9 col-lg-10 col-secondary-end py-2 gray-border'>
                                                                        {responseEval['evaluationTypes'][key].resultDetail}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </> : responseEval['evaluationTypes'][key].evaluationAux && !responseEval['evaluationTypes'][key].errorMsg &&
                                                        <>
                                                            {Object.entries(responseEval['evaluationTypes'][key].evaluationAux).map(([keyDNSH, valDNSH]) => {
                                                               if (valDNSH.resultCode === 'KO') { 
                                                                resDNSH = 'KO'; 
                                                                detailDNSH.push(<><ObjectiveImage objectiveId={keyDNSH} /> {valDNSH.resultDetail}</>)
                                                                }
                                                            })}
                                                            {/* {setCountResponse(countResponse+1)} */}
                                                            <div className='content'>
                                                                <div className='row'>
                                                                    <div className='col-sm-3 col-lg-2 col-primary py-2 d-flex align-items-center'>
                                                                        Resultado
                                                                    </div>
                                                                    <div className='col-sm-9 col-lg-10 col-secondary py-2 gray-border'>
                                                                        {resDNSH === 'OK' ?
                                                                            <div>
                                                                                <div>
                                                                                    <BsCheckCircleFill className='color-succes pb-0 me-2' />
                                                                                    Cumple
                                                                                </div>
                                                                            </div> : 
                                                                            (resDNSH === 'KO' ?
                                                                                <div>
                                                                                    <div>
                                                                                        <MdCancel className='color-fail pb-0 me-2' />
                                                                                        No Cumple
                                                                                    </div>
                                                                                </div>: 
                                                                                <div>
                                                                                    <div>
                                                                                        <MdCancel className='color-pending pb-0 me-2' />
                                                                                        No evaluado
                                                                                    </div>
                                                                                </div>
                                                                                )
                                                                            }
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-sm-3 col-lg-2 col-primary-end py-2'>
                                                                        Detalle de la evaluación
                                                                    </div>
                                                                    <div className='col-sm-9 col-lg-10 col-secondary-end py-2 gray-border'>
                                                                        {responseEval['evaluationTypes'][key].resultDetail ? responseEval['evaluationTypes'][key].resultDetail :
                                                                            detailDNSH.length === 1 ? detailDNSH[0] :
                                                                                detailDNSH.map((det) => <p>{det}</p>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className='special-block'>
                                                <div className='bg-white special-content d-flex justify-content-end'>
                                                {(info.state !== 2) &&
                                                <>
                                                    <Button className='btn btn-secondary color-white mid-button long-button' type="submit" onClick={(event) => { handleEval(event, obj.idEvaluationType) }}>
                                                        Evaluar
                                                    </Button>
                                                    <Button className='btn btn-secondary color-white ms-4 mid-button long-button' type="submit" onClick={(event) => { handleSave(event, obj.idEvaluationType) }}>
                                                        Guardar
                                                    </Button>
                                                </>
                                                }
                                                {(responseEval && responseEval['evaluationTypes'] && info && info.evaluationTypes && Object.keys(responseEval['evaluationTypes']).length === Object.keys(info.evaluationTypes).length && info.state !== 2) ?
                                                <>
                                                    <PopOver title={<Button className='btn btn-secondary color-white ms-4 mid-button long-button' onClick={() => { setClickFin(true) }}>Finalizar</Button>} message={'Finalizar Evaluación'} position='bottom'/>
                                                    <ModalWarning header={<span className='section-title-modal'>Finalizar evaluación</span>} message={"Se finalizará la evaluación. Al hacer click en continuar no se podrán cambiar las respuestas"} show={clickFin} handleContinue={handleEndEvaluation} handle={handleClickState}></ModalWarning>
                                                </> 
                                                :
                                                (esBloque === 0)?
                                                    (info && info.state !== 2) ?
                                                        <Button className='btn btn-secondary color-white ms-4 mid-button long-button' disabled={true}>Finalizar</Button>
                                                        :
                                                        <Button className='btn btn-secondary color-white ms-4 mid-button long-button' onClick={() => (handleDownload("0",evaluationId,1))}>Descargar PDF</Button>
                                                :<></> 
                                            }
                                            <ModalWarning header={<span className='section-title-modal'>Aviso</span>} message={msgSave} show={showSave} handle={handleShowModal} onlyButtonContinue ></ModalWarning>
                                                </div>
                                            </div>
                                    </Form>
                                </Tab>
                            )
                            )
                        }
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

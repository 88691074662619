class taxoApi {

  static dev = "o90nmc1zsn0"
  static pro = "r0r0nmrr26"
  static sufijoDev = ""
  static sufijoPro = "-lambda-prod"
  static sufijo = this.sufijoPro
  static entorno = this.pro
  static apiURLBase = "https://" + this.entorno + ".execute-api.eu-west-1.amazonaws.com"
  static apiURLDev = "https://test-apialamoesg.alamoconsulting.com"
  static apiURLPro = "https://apialamoesg.alamoconsulting.com"
  static apiURL = this.apiURLBase

  static getAllSectors(navigate) {

    return fetch(this.apiURL + "/taxo-allsectors" + this.sufijo, {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getAllActivities(navigate) {
    return fetch(this.apiURL + "/taxo-allactivities" + this.sufijo, {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getAllObjetives(navigate) {
    return fetch(this.apiURL + "/taxo-allobjetives" + this.sufijo, {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getAllSectorInstitutionals(navigate) {
    return fetch(this.apiURL + "/taxo-allSectorInstitutionals" + this.sufijo, {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getAllQuestions(navigate) {
    return fetch(this.apiURL + "/taxo-objetivequestion" + this.sufijo, {
      method: 'GET',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

    static getAllActivities(navigate) {
    return fetch(this.apiURL + "/taxo-allactivities" + this.sufijo, {
      method: 'get',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getSaveQueries(navigate){
    const key = localStorage.getItem("id_entidad")
    debugger
    const raw = JSON.stringify({entityId: key});
    return fetch(this.apiURL + "/taxo-saveQueries" + this.sufijo, {               
      method: 'POST',
      headers: {Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        debugger
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getInfoDashboard(navigate){
    const key = localStorage.getItem("id_entidad")
    const raw = JSON.stringify({entityId: key});
    return fetch(this.apiURL + "/taxo-GetInfoDashboard" + this.sufijo, {
      method: 'POST',
      headers: {Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static searchSaveQueries(response, navigate){
    const key = localStorage.getItem("id_entidad")
    const raw = JSON.stringify({data: response, entityId: key});
    return fetch(this.apiURL + "/taxo-searchSaveQueries" + this.sufijo, {
      method: 'POST',
      headers: {Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  



  static getObjectiveActivity(activityId, objectiveId, operationId, navigate) {
    const url = this.apiURL + "/taxo-objetivequestion" + this.sufijo;
    // const myHeaders = new Headers({ Authorization: localStorage.getItem("token-imcapp") });
    //   const myHeaders = new Headers()
    /* myHeaders.append('user', 'admin')
     myHeaders.append('password', 'admin')
     myHeaders.append('Content-Type', 'application/json;charset=UTF-8')
     myHeaders.append('Access-Control-Allow-Origin','*')*/

    const controllerc = new AbortController()
    const signalc = controllerc.signal

    const key = localStorage.getItem("id_entidad")
    const raw = JSON.stringify({ activityId: activityId, objectiveId: objectiveId, operationId: operationId,entityId: key });
    // const options = {
    //   method: "POST",
    //   signal: signalc,
    //   headers: {
    //     user: 'admin',
    //     password: 'admin'
    //   },
    //   body: raw,
    // };
    //return fetch(url, options)
    return fetch(url, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json();
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getObjectiveActivityResponse(activityId, objectiveId, evaluationId, navigate) {
    const key = localStorage.getItem("id_entidad")
    const url = this.apiURL + "/taxo-objetivequestionresponse" + this.sufijo;

    const controllerc = new AbortController()
    const signalc = controllerc.signal


    const raw = JSON.stringify({ activityId: activityId, objectiveId: objectiveId, evaluationId: evaluationId,entityId: key});

    return fetch(url, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json();
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoReopenEvaluation(response, navigate) {
    const url = this.apiURL + "/taxo-reopenEvaluation" + this.sufijo;
    const raw = JSON.stringify({ data: response });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido finalizar la evaluación correctamente',
          error: error
        }
      }));
  }

  static getTaxoEndEvaluation(response, navigate) {
    const url = this.apiURL + "/taxo-endEvaluation" + this.sufijo;
    response.entityName = localStorage.getItem("desc_entidad")
    const raw = JSON.stringify({ data: response });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido finalizar la evaluación correctamente',
          error: error
        }
      }));
  }

  static getTaxoSave(id, response, navigate) {
    const url = this.apiURL + "/taxo-saveEvaluation" + this.sufijo;
    response.userId = localStorage.getItem("id_usuario")
    const raw = JSON.stringify({ idEvaluationType: id, data: response });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido guardar correctamente',
          error: error
        }
      }));
  }

  static getTaxoSaveComment(id, commentIn, navigate) {
    
    const url = this.apiURL + "/taxo-saveQueryComments" + this.sufijo;
    const raw = JSON.stringify({ idEvaluation: id, comment: commentIn });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido guardar correctamente el comentario',
          error: error
        }
      }));
  }

  static getTaxoGetQueryComments(id, navigate) {
    const url = this.apiURL + "/taxo-getQueryComments" + this.sufijo;
    const raw = JSON.stringify({ idEvaluation: id });
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 404,
          statusText: 'No se ha podido recuperar correctamente los comentarios',
          error: error
        }
      }));
  }


  static getTaxoEvaluation(id, responses, navigate) {

    const url = this.apiURL + "/taxo-evaluation" + this.sufijo;
    responses.userId = localStorage.getItem("id_usuario")

    const raw = JSON.stringify({ idEvaluationType: id, data: responses});
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw,
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        else if (response.ok) {
          return response.json()
        }
        else {
          throw new Error(response);
        }
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos',
          error: error
        }
      }));
  }



  static getActivitiesNACE(navigate) {
    const controllerc = new AbortController()
    const signalc = controllerc.signal
    const url = this.apiURL + "/taxo-ActivitiesNACE" + this.sufijo;
    //const raw = JSON.stringify({ NACEIdKey: naceId});
    const options = {
      method: "POST",
      signal: signalc,
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getActivitiesPurpose(navigate) {

    const url = this.apiURL + "/taxo-ActivitiesPurpose" + this.sufijo;
    //const raw = JSON.stringify({ NACEIdKey: naceId});
    const options = {
      method: "POST",
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value }
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static authLogin({ user, password }) {

    const url = this.apiURL + "/taxo-login-auth" + this.sufijo;        
    const myHeaders = new Headers();
    const raw = JSON.stringify({ user: user, pwd: password });
    const options = {
      method: "POST",
      headers: myHeaders,
      body: raw
    };
    return fetch(url, options)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoBloques(navigate) {
    const key = localStorage.getItem("id_entidad")
    const raw = JSON.stringify({entityId: key});
    return fetch(this.apiURL + "/taxo-getBloques" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoConsultasBloque(blockId, navigate) {
    const raw = JSON.stringify({ bloque: blockId});
    return fetch(this.apiURL + "/taxo-getConsultasBloque" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoSummaryEvaluation(evaluationId, navigate) {
    const raw = JSON.stringify({ evaluationId: evaluationId});
    return fetch(this.apiURL + "/taxo-SummaryEvaluation" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoDownloadFile(Path, evaluationId, searchPath, navigate) {
    const raw = JSON.stringify({ bloque: Path, evaluationId:evaluationId, searchPath:searchPath });
    return fetch(this.apiURL + "/taxo-downloadFile" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }
  
  static getTaxoCheckFile(evaluationId,fileType,navigate) {
    const raw = JSON.stringify({ evaluationId: evaluationId, fileType:fileType});
    // Si, Es una lambda reutilizada, habria que cambiarle el nombre.. :)
    return fetch(this.apiURL + "/taxo-disableEvaluation" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoCheckBloque(evaluationId,navigate) {
    const raw = JSON.stringify({ evaluationId: evaluationId});
    // Si, Es una lambda reutilizada, habria que cambiarle el nombre.. :)
    return fetch(this.apiURL + "/taxo-generateEvaluationFile_TEST" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoDeleteBatch(blockId, navigate) {
    const raw = JSON.stringify({ bloque: blockId});
    return fetch(this.apiURL + "/taxo-deleteBatch" + this.sufijo, {
      method: 'DELETE',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoDeleteQuery(id, navigate) {
    const raw = JSON.stringify({ idEvaluation: id});
    return fetch(this.apiURL + "/taxo-deleteQuery" + this.sufijo, {
      method: 'DELETE',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoUploadFile(evaluationId, file, type, navigate) {
    const raw = new FormData();
    raw.append('evaluationId', evaluationId)
    raw.append('fileTypeId', type)
    raw.append('File', file);
    

    return fetch(this.apiURL + "/taxo-uploadQueryDocument" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoUploadAppFile(file, blockName, activity, objective, entity, navigate) {
    const raw = new FormData();
    raw.append('blockName', blockName)
    raw.append('activity', activity)
    raw.append('objective', objective)
    raw.append('entity', entity)
    raw.append('File', file);
    

    return fetch(this.apiURL + "/taxo-uploadAppFile" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value},
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }
  
  static getTaxoDocumentTypes(navigate) {
    return fetch(this.apiURL + "/taxo-getDocumentTypes" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static getTaxoDocuments(id, navigate) {
    let raw = JSON.stringify({ evaluationId: id});
    return fetch(this.apiURL + "/taxo-getQueryDocuments" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static deleteTaxoDocument(fileId, evaluationId, navigate) {
    let raw = JSON.stringify({ fileId: fileId, evaluationId: evaluationId});
    return fetch(this.apiURL + "/taxo-deleteQueryDocument" + this.sufijo, {
      method: 'DELETE',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static processFile(key, activity, objective, entity, blockName, /* singleEv, */ navigate) {
    const user = localStorage.getItem("id_usuario")
    let raw = JSON.stringify({ key: key, activity: activity, objective: objective, blockName: blockName, user: user, entity: entity});
    return fetch(this.apiURL + "/taxo-processingFile" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static evaluateBlock(idBloque, navigate) {
    const idUsuario = localStorage.getItem("id_usuario")
    let raw = JSON.stringify({ idBloque: idBloque , idUsuario: idUsuario});
    return fetch(this.apiURL + "/taxo-batchEvaluation" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

  static deleteComment(idComment, navigate) {
    let raw = JSON.stringify({ idComment: idComment});
    return fetch(this.apiURL + "/taxo-deleteComment" + this.sufijo, {
      method: 'POST',
      headers: { Authorization: JSON.parse(localStorage.getItem("token-taxo")).value },
      body: raw
    })
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          localStorage.setItem("sesion","caducada");
          navigate("/login")
        }
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(error => ({
        error: {
          status: 401,
          statusText: 'No se han podido obtener los datos'
        }
      }));
  }

}



export default taxoApi;
